import React, { useState,useEffect} from 'react';
import { Link} from 'react-router-dom';
import swal from "sweetalert";
import {nanoid} from 'nanoid';

import { useDispatch,useSelector } from "react-redux";

import {
	Row,
	Col,
	Card,
	Table,
	Badge,
	Dropdown,
	Modal,
	ProgressBar,
 } from "react-bootstrap";

 /// imge
import avatar1 from "../../../images/avatar/1.jpg";
import avatar2 from "../../../images/avatar/2.jpg";
import avatar3 from "../../../images/avatar/3.jpg";


// Components
import CardItem from "../../components/card-item/card-item";

//Actions
import { getAllCards } from '../../../store/actions/cardActions';

import {
   
	getInvitationsAction,
   
} from '../../../store/actions/MemberActions';



const CardPage = ({ props, history }) => {
	
	
	const dispatch = useDispatch();

	

	const getAllCard = useSelector((state) => state.getAllCard);
	const { cards, allCardLoading, allCardError } = getAllCard;

	//const { invitations} = getInvitations;
	const invitations = useSelector((state) => state.members.invitations);

	useEffect(() => {
		dispatch(getInvitationsAction({}));
		dispatch(getAllCards());
	  }, [dispatch]);

    
	  useEffect(() => {
		if (cards) {
		  console.log("cards founds");
		  //toast.success("cards founds");
		}
	
		if (allCardLoading) {
		  console.log("allCardLoading");
		} else {
		  console.log("allCardLoading false");
		}
	
		if (allCardError) {
		  console.log("allCardError");
		  //toast.error(allCardError);
		}
	  }, [cards, allCardLoading, allCardError]);
    

	  const memberName  = (cardId) => {
		const memberIndex = invitations.findIndex(
			(x) => x.cardId === cardId
		  );
		  if (memberIndex != -1) {
			return invitations[memberIndex].email;
		  }else{
			return "";
		  }
	  }
   
    
    return(
        <>
			
			

			<div class="row pb-5 mb-4">
                        {!allCardLoading && cards ? (
                          cards.map((card,i) => (
                            <CardItem key={card._id} name={memberName(card._id)} card={card} isEven={ (i == 0) || (i % 2  == 0)}></CardItem>
                          ))
                        ) : (
                          <div>Loading cards...</div>
                        )}
                      </div>

			

            
        </>
    );     
}

export default CardPage;