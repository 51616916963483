import React, { Component } from "react";

/// Link
import { Link } from "react-router-dom";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";

///
 import drump from "../../../images/card/drump.png";

class MM extends Component {
   componentDidMount() {
      this.$el = this.el;
      this.mm = new MetisMenu(this.$el);
   }
   componentWillUnmount() {
      // this.mm("dispose");
      // console.log(this.mm);
   }
   render() {
      return (
         <div className="mm-wrapper">
            <ul className="metismenu" ref={(el) => (this.el = el)}>
               {this.props.children}
            </ul>
         </div>
      );
   }
}

class SideBar extends Component {
   /// Open menu
   componentDidMount() {
      // sidebar open/close
      var btn = document.querySelector(".nav-control");
      var aaa = document.querySelector("#main-wrapper");

      function toggleFunc() {
         return aaa.classList.toggle("menu-toggle");
      }

      btn.addEventListener("click", toggleFunc);   
   }
   render() {
      /// Path
      let path = window.location.pathname;
      path = path.split("/");
      path = path[path.length - 1];

      /// Active menu
      let deshBoard = [
             "",
            // "workout-statistic",
            // "workout-plan",
            // "distance-map",
            // "diet-food-menu",
            // "personal-record",
         ];
       /*   app = [
            "app-profile",
            "post-details",
            "app-calender",
            "email-compose",
            "email-inbox",
            "email-read",
            "ecom-product-grid",
            "ecom-product-list",
            "ecom-product-list",
            "ecom-product-order",
            "ecom-checkout",
            "ecom-invoice",
            "ecom-customers",
            "post-details",
            "ecom-product-detail",
         ],
         email = ["email-compose", "email-inbox", "email-read"],
         shop = [
            "ecom-product-grid",
            "ecom-product-list",
            "ecom-product-list",
            "ecom-product-order",
            "ecom-checkout",
            "ecom-invoice",
            "ecom-customers",
            "ecom-product-detail",
         ],
         charts = [
            "chart-rechart",
            "chart-flot",
            "chart-chartjs",
            "chart-chartist",
            "chart-sparkline",
            "chart-apexchart",
         ],
         bootstrap = [
            "ui-accordion",
            "ui-badge",
            "ui-alert",
            "ui-button",
            "ui-modal",
            "ui-button-group",
            "ui-list-group",
            "ui-media-object",
            "ui-card",
            "ui-carousel",
            "ui-dropdown",
            "ui-popover",
            "ui-progressbar",
            "ui-tab",
            "ui-typography",
            "ui-pagination",
            "ui-grid",
         ],
         plugins = [
            "uc-select2",
            "uc-nestable",
            "uc-sweetalert",
            "uc-toastr",
            "uc-noui-slider",
            "map-jqvmap",
			"uc-lightgallery",
         ],
         widget = ["widget-basic"],
         forms = [
            "form-element",
            "form-wizard",
            "form-editor-summernote",
            "form-pickers",
            "form-validation-jquery",
         ],
         table = ["table-bootstrap-basic", "table-datatable-basic"],
         pages = [
            "page-registser",
            "page-login",
            "page-lock-screen",
            "page-error-400",
            "page-error-403",
            "page-error-404",
            "page-error-500",
            "page-error-503",
         ],
         error = [
            "page-error-400",
            "page-error-403",
            "page-error-404",
            "page-error-500",
            "page-error-503",
         ]; */
      return (
         <div className="deznav">
            <PerfectScrollbar className="deznav-scroll">
               <MM className="metismenu" id="menu">
                  <li
                     className={`${
                        deshBoard.includes(path) ? "mm-active" : ""
                     }`}
                  >
					<Link className="has-arrow ai-icon" to="#">
						<i className="flaticon-381-networking"></i>
						<span className="nav-text">Dashboard</span>
					</Link>
						<ul>
							<li>
								<Link className={`${path === "" ? "mm-active" : ""}`} to="/">Dashboard</Link>
							</li>
							<li>
							   <Link className={`${ path === "members" ? "mm-active" : "" }`} to="/members">
									Members
							   </Link>
							</li>
							<li>
							   <Link className={`${ path === "cards" ? "mm-active" : ""}`} to="/cards">
									Cards
							   </Link>
							</li>
							<li>
							   <Link className={`${ path === "profiles" ? "mm-active" : "" }`} to="/profiles">
									Profiles
							   </Link>
							</li>
							{/* <li>
							   <Link className={`${ path === "diet-food-menu" ? "mm-active" : "" }`} to="/diet-food-menu">
									Diet Food Menu
							   </Link>
							</li>
							<li>
							   <Link className={`${ path === "personal-record" ? "mm-active" : "" }`} to="/personal-record">
									Personal Record
							   </Link>
							</li>
							<li>
								<Link className={`${ path === "task" ? "mm-active" : "" }`} to="/task">Task </Link>
							</li> */}
						</ul>
                  </li>
					
               </MM>
              

               <div className="copyright">
                  <p>
                     <strong>Tap1ce Corporate</strong> ©All Rights Reserved
                  </p>
                  <p>By Comket Solution Pvt Ltd.</p>
               </div>
            </PerfectScrollbar>
         </div>
      );
   }
}

export default SideBar;
